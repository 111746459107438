import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LandingViewComponent } from './views/landing-view/landing-view.component';

const routes: Routes = [
  {
    path: 'code',
    loadChildren: () =>
      import('@maryl.pl/views/code').then((m) => m.ViewsCodeModule),
  },
  {
    path: '',
    pathMatch: 'full',
    component: LandingViewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
