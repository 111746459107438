import { Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'maryl-landing-view',
  templateUrl: './landing-view.component.html',
  styleUrls: ['./landing-view.component.scss'],
})
export class LandingViewComponent {
  public lottieOptions: AnimationOptions = {
    path: 'https://assets10.lottiefiles.com/private_files/lf30_WdTEui.json',
  };

  // https://assets7.lottiefiles.com/packages/lf20_mdbdc5l7.json
}
